import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image';
import { Price } from '@jetshop/ui/Price';
import { styled } from 'linaria/react';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import { css, cx } from 'linaria';
import React from 'react';
import { theme } from '../Theme';
import { Favourite } from '../ProductList/Favourite';
import { ReactComponent as FavouritesCart } from '../../svg/FavouritesCart.svg';
import { usePriceDisplay } from '../../hooks/usePriceDisplay';
import { RekPrice } from '../ui/RecPrice';
import CustomBadges from '../ui/CustomBadges';
import { useVariantFromUrl } from '@jetshop/core/hooks/useProductVariants/useVariantFromUrl';
import { useProductVariants } from '@jetshop/core/hooks/useProductVariants';
import { VariantSelectorProductCard } from '../ProductPageNew/components/AddToCart/VariantSelectorProductCard';

const wrapperStyling = css`
  font-size: 1rem;
  position: relative;

  a {
    text-decoration: none;
    color: inherit;
    display: block;
    background: white;
  }

  /* .no-image {
    [data-flight-image] {
      img {
        mix-blend-mode: unset;
      }
    }
  } */

  [data-flight-image-container] {
    background: ${theme.colors.lightgrey};
  }
  [data-flight-image] {
    img {
      mix-blend-mode: multiply;
      transform: translate3d(0, 0, 0);
    }
  }

  .product-card-detail {
    font-family: ${theme.fonts.primaryFontFamily};
    background: white;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.04em;
      color: ${theme.colors.darkgrey};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin: 0;
    }

    h4 {
      font-weight: 400;
      font-size: 13px;
      line-height: 17px;
      color: ${theme.colors.darkgrey};
      font-family: ${theme.fonts.primaryFontFamily};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin: 0;
    }
    .product-article-number {
      display: block;
      font-size: 14px;
      font-style: italic;
    }
  }
  .package-badge {
    background: black;
    color: white;
    font-size: 14px;
    padding: 3px 6px;
  }
  .price-package-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    .package-price-label {
      font-size: 12px;
      color: grey;
      margin-left: 5px;
    }
    p {
      margin: 0;
      font-family: ${theme.fonts.primaryFontFamily};
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #797979;
    }
  }

  .badge-top-right {
    top: 3em;
  }
  .quickbuy {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      z-index: 2;
      padding: 10px;
      border-radius: 50%;
      margin-top: 10px;
      svg {
        width: 19px;
        height: 16px;
      }
    }
  }
`;

const FavouriteWrapper = styled('div')`
  position: absolute;
  top: 5px;
  right: 20px;
  z-index: 1;

  button {
    border: none;
  }
  svg {
    width: 1.3rem !important;
  }
`;

export const StyledPrice = styled(Price)`
  display: flex;
  align-items: flex-end;

  .price {
    margin-top: 10px;
    font-family: ${theme.fonts.primaryFontFamily};
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: #282828;
  }
  .new-price {
    margin-top: 10px;
    font-family: ${theme.fonts.primaryFontFamily};
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: #c94b4b;
  }
  .old-price {
    font-size: 15px;
    color: #797979;
    text-decoration: line-through;
    margin-left: 10px;
  }
`;

export function ProductCard({
  product,
  className,
  imageAspect = '66:83',
  // imageAspect = '190:206',
  imageSizes = [1 / 2, 1 / 2, 1 / 3, 1 / 4],
  forwardRef,
  as = 'li',
  children,
  loadImageEagerly = false,
  favourites,
  ...linkProps
}) {
  const { showPrice } = usePriceDisplay();
  const hasImages = product?.images && product?.images.length > 0;
  const noImage =
    'https://willabb2c-hansbo.jetshop.se/pub_docs/files/Hmissing.jpg';

  const Tag = as;
  let badges = [...(product?.badges || [])];
  product?.isPackage &&
    badges.push({
      location: 'TOP_RIGHT',
      name: 'Package',
      text: t('Package'),
      style: 'package-badge'
    });

  // const brand = product?.customFields?.filter(
  //   customField => customField.key === 'Varumarke'
  // );

  // GET INITIAL VARIANT FROM URL
  const initialVariant = useVariantFromUrl();
  // DEFINE VARIANTHANDLER TO USE PRODUCTVARIANTS
  const variantHandler = useProductVariants(product, {
    initialVariant,
    preselectCheapest: false
  });
  // GET SELECTED VARIANT
  const {
    selectedVariant: selectedVariation,
    getMissingOptions
  } = variantHandler;

  return (
    <Tag
      className={cx('product-card', wrapperStyling, className)}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <ProductLink
        className="product-link"
        product={product}
        {...linkProps}
        style={{
          margin: '0 10px 10px'
        }}
      >
        {hasImages ? (
          <>
            <Image
              sizes={imageSizes}
              aspect={imageAspect}
              alt={product?.images?.[0]?.alt}
              src={product?.images?.[0]?.url}
              modifiedDate={product?.images?.[0].modifiedDate}
              badges={<CustomBadges badges={badges} />}
              critical={loadImageEagerly}
            />
            <FavouriteWrapper className="favourite-wrapper">
              <Favourite product={product} />
            </FavouriteWrapper>
          </>
        ) : (
          noImage && (
            <>
              <Image
                className="no-image"
                aspect={imageAspect}
                src={noImage}
                alt="noimage"
                critical={loadImageEagerly}
                badges={<CustomBadges badges={badges} />}
              />
              <FavouriteWrapper className="favourite-wrapper">
                <Favourite product={product} />
              </FavouriteWrapper>
            </>
          )
        )}
        <section className="product-card-detail">
          <header>
            <h3>{product?.name}</h3>
            {/* <h4 className="sub-name">{product.name}</h4> */}
            {/* <span className="product-article-number">
              Art.nr: {product.articleNumber}
            </span> */}
          </header>
          <div className="price-package-wrapper">
            <div className="quickbuy">
              <StyledPrice
                hidePrice={product?.hidePrice}
                price={product?.price}
                previousPrice={product?.previousPrice}
              />
              {favourites && (
                <button onClick={() => console.log('click')}>
                  <FavouritesCart />
                </button>
              )}
            </div>
            {/* <RekPrice product={product} /> */}
          </div>

          <VariantSelectorProductCard
            product={product}
            variantHandler={variantHandler}
          />
        </section>
      </ProductLink>
      {children}
    </Tag>
  );
}
